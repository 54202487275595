/**
 * Copyright(c) 2021 Mozanta Technologies Private Ltd.
 *
 * All rights reserved.
 *
 * This software is the confidential and proprietary information of Mozanta
 * ("Confidential Information"). You shall not disclose such Confidential
 * Information and shall use it only in accordance with the terms of the
 * contract agreement you entered into with Mozanta.
 *
 * authServerApi
 *
 * @author Anokh J Ajai
 *
 */
const axios = require('axios');
const { navigate } = require('gatsby');
const config = require('../config/config');
const { UserData } = require('../utils/localStorageUtils');

const { authServerHost } = config;

const instance = axios.create({
  baseURL: authServerHost,
});

const errorCodes = [500, 401];

const logout = async apiUrl => {
  const { hostname: serviceUrl } = apiUrl ? new URL(apiUrl) : {};
  const token = UserData.getToken();
  const logoutRequest = {
    token,
    appId: 'Mozcom',
    context: 'Storefront',
    serviceUrl,
  };
  UserData.clearAll();
  if (token) {
    const response = await instance.post('/auth/v1/b2b/logout', logoutRequest);
    return response ? response.data : null;
  }
  return null;
};

instance.interceptors.response.use(
  response => response,
  error => {
    const { response } = error;
    if (response && errorCodes.includes(response.status)) {
      UserData.clearAll();
      // redirect to login page
      // use gatsby's  naviagte here. Build fails if custom navigates is used here.
      return navigate('/login');
    }
    return response;
  },
);

const login = async (loginData, apiUrl) => {
  const { hostname: serviceUrl } = apiUrl ? new URL(apiUrl) : {};
  const loginRequest = {
    ...loginData,
    serviceUrl,
    grantType: 'Password',
    appId: 'MozCom',
    context: 'StoreFront',
  };
  const response = await instance.post('/auth/v1/b2b/token', loginRequest);
  return response ? response.data : null;
};

const getAnonymousToken = async apiUrl => {
  const { hostname: serviceUrl } = apiUrl ? new URL(apiUrl) : {};
  const tokenRequest = {
    serviceUrl,
    grantType: 'Anonymous',
    appId: 'MozCom',
    context: 'StoreFront',
  };
  const response = await instance.post('/auth/v1/b2b/token', tokenRequest);
  if (response && response.data) {
    const { data: responseData } = response;
    if (responseData) {
      const { data } = responseData;
      if (data) {
        const { token } = data;
        return token;
      }
    }
  }
  return null;
};

const renewToken = async (refreshToken, apiUrl) => {
  const { hostname: serviceUrl } = apiUrl ? new URL(apiUrl) : {};
  const tokenRefreshRequest = {
    serviceUrl,
    refreshToken,
    appId: 'MozCom',
  };
  const response = await instance.put('/auth/v1/b2b/token', tokenRefreshRequest);
  return response;
};

const verifyToken = async (token, apiUrl) => {
  const { hostname: serviceUrl } = apiUrl ? new URL(apiUrl) : {};
  const tokenVerificationRequest = {
    serviceUrl,
    token,
    appId: 'MozCom',
  };
  const response = await instance.put('/auth/v1/b2b/token-validation', tokenVerificationRequest);
  return response;
};

const updateTokenLocation = async (requestData, token, apiUrl) => {
  const { hostname: serviceUrl } = apiUrl ? new URL(apiUrl) : {};
  const tokenUpdateRequest = {
    serviceUrl,
    ...requestData,
    appId: 'MozCom',
    token,
  };
  const response = await instance.post('/auth/v1/b2b/location', tokenUpdateRequest);
  return response;
};

const impersonateLogin = async (impersonateUsername, token, apiUrl) => {
  const { hostname: serviceUrl } = apiUrl ? new URL(apiUrl) : {};
  const tokenImpersonateRequest = {
    token,
    serviceUrl,
    appId: 'MozCom',
    username: impersonateUsername,
    context: 'StoreFront',
  };
  const response = await instance.post('/auth/v1/b2b/impersonate', tokenImpersonateRequest);
  return response ? response.data : null;
};

const updateTokenWithB2BDepartment = async (requestData, token, apiUrl) => {
  const { hostname: serviceUrl } = apiUrl ? new URL(apiUrl) : {};
  const tokenUpdateRequest = {
    serviceUrl,
    ...requestData,
    appId: 'MozCom',
    token,
  };
  const response = await instance.post('/auth/v1/b2b/b2bdepartment', tokenUpdateRequest);
  return response;
};

const updateTokenWithCustomerAccount = async (requestData, token, apiUrl) => {
  const { hostname: serviceUrl } = apiUrl ? new URL(apiUrl) : {};
  const tokenUpdateRequest = {
    serviceUrl,
    ...requestData,
    appId: 'MozCom',
    token,
  };
  const response = await instance.post('/auth/v1/b2b/customer-account', tokenUpdateRequest);
  return response;
};

const updateTokenWithBrand = async (requestData, token, apiUrl) => {
  const { hostname: serviceUrl } = apiUrl ? new URL(apiUrl) : {};
  const tokenUpdateRequest = {
    serviceUrl,
    ...requestData,
    appId: 'MozCom',
    token,
  };
  const response = await instance.post('/auth/v1/b2b/brand', tokenUpdateRequest);
  return response;
};

module.exports = {
  login,
  logout,
  getAnonymousToken,
  renewToken,
  verifyToken,
  updateTokenLocation,
  impersonateLogin,
  updateTokenWithB2BDepartment,
  updateTokenWithCustomerAccount,
  updateTokenWithBrand,
};
