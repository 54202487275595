/**
 * Copyright(c) 2021 Mozanta Technologies Private Ltd.
 *
 * All rights reserved.
 *
 * This software is the confidential and proprietary information of Mozanta ("Confidential
 * Information"). You shall not disclose such Confidential Information and shall use it only in
 * accordance with the terms of the contract agreement you entered into with Mozanta.
 *
 * @author Anokh J Ajai
 */

const axios = require('axios');
const { navigate } = require('gatsby');
const authServerApi = require('./authServerApi');
const config = require('../config/config');
const { UserData } = require('../utils/localStorageUtils');

const { baseURL } = config;
const { CancelToken } = axios;

const instance = axios.create({
  baseURL,
  withCredentials: true,
});

const setAuthHeader = token => {
  const authHeader = `Bearer ${token}`;
  instance.defaults.headers.common.Authorization = authHeader;
};

const getSelectedSubAccount = () => {
  const user = UserData.getUser();
  if (user) {
    return user.selectedAccount;
  }
  return null;
};

const getApiBaseUrl = () => instance.defaults.baseURL;

instance.interceptors.response.use(
  response => response.data,
  async error => {
    const { response, config: originalRequest } = error;
    if (response && response.status === 401) {
      if (!originalRequest.retry) {
        originalRequest.retry = true;
        const currentRefreshToken = UserData.getRefreshToken();
        const { status, data } = await authServerApi.renewToken(currentRefreshToken, getApiBaseUrl()) || {};
        if (status === 200) {
          const { data: { token, refreshToken } } = data;
          UserData.setToken(token);
          UserData.setRefreshToken(refreshToken);
          setAuthHeader(token);
          if (originalRequest.data) {
            originalRequest.data = JSON.parse(originalRequest.data);
          }
          const instanceResponse = await instance(originalRequest);
          return instanceResponse;
        }
      }
      // Seems the token is expired. Destroying existing session
      // redirect to login page
      // use gatsby's  naviagte here. Build fails if custom navigates is used here.
      return authServerApi.logout(getApiBaseUrl()).finally(() => navigate('/login'));
    }
    return response;
  },
);

instance.interceptors.request.use(
  httpConfig => {
    const token = UserData.getToken();
    if (!token) {
      // Seems the token is expired. Destroying existing session
      // redirect to login page
      // use gatsby's  naviagte here. Build fails if custom navigates is used here.
      return authServerApi.logout(getApiBaseUrl()).finally(() => navigate('/login'));
    }
    const locale = UserData.getLocale();
    const nConfig = { ...httpConfig };
    const { headers } = httpConfig;
    const b2bDepartment = getSelectedSubAccount();
    nConfig.headers = { Authorization: `Bearer ${token}`, 'Mozcom-Locale': locale };
    if (b2bDepartment) {
      nConfig.headers['Mozcom-B2BDepartment-Id'] = b2bDepartment;
    }
    const catalogueType = headers && headers.catalogueType
      ? headers.catalogueType
      : UserData.getCatalogueType();
    if (catalogueType) {
      nConfig.headers['Mozcom-Catalog-Type'] = catalogueType;
    }
    return nConfig;
  },
  error => error,
);

const getCancelToken = () => {
  let cancelMethod;
  const cancelToken = new CancelToken((c => { cancelMethod = c; }));
  return { cancelToken, cancelMethod };
};
const getCancelTokenV2 = () => {
  const cancelToken = CancelToken.source();
  return cancelToken;
};

module.exports = {
  get: instance.get,
  post: instance.post,
  put: instance.put,
  patch: instance.patch,
  delete: instance.delete,
  appApiBaseUrl: instance.defaults.baseURL,
  getApiBaseUrl,
  getCancelToken,
  getCancelTokenV2,
};
