require('./src/styles/fenix/scss/main.scss');

const { verifyToken, logout } = require('@api/authServerApi');
const { getApiBaseUrl } = require('@api/httpApi');
const { UserData } = require('@utils/localStorageUtils');
const { navigate } = require('gatsby');
const { unRestrictedPaths, semiRestrictedPaths } = require('./src/utils/constants');

const verifyAuth = async () => {
  const token = UserData.getToken();
  if (token) {
    const response = await verifyToken(token, getApiBaseUrl());
    if (response && response.data && response.data.data && response.data.data.valid) {
      return true;
    }
  }
  return logout(getApiBaseUrl()).finally(() => navigate('/login'));
};

exports.onPreRouteUpdate = async ({ location }) => {
  const path = location.pathname;
  const token = UserData.getToken();
  const isUnRestrictedPath = unRestrictedPaths.includes(path) || (semiRestrictedPaths.includes(path) && !token);
  if (!isUnRestrictedPath) {
    await verifyAuth();
  }
};
