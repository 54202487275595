/**
 * Copyright(c) 2020 Mozanta Technologies Private Ltd.
 *
 * All rights reserved.
 *
 * This software is the confidential and proprietary information of Mozanta ("Confidential
 * Information"). You shall not disclose such Confidential Information and shall use it only in
 * accordance with the terms of the contract agreement you entered into with Mozanta.
 *
 * @author Anokh J Ajai
 */

const facetNames = {
  CATEGORY: 'Category',
  COLOR: 'Color',
  SIZE: 'Size',
  FAMILY: 'Family',
  MATERIAL: 'Material',
  SEASON: 'Season',
  ACTIVITY: 'Activity',
  PRODUCT_RELEVANCE: 'ProductRelevance',
  VARIANT_RELEVANCE: 'VariantRelevance',
};

const facetCodes = {
  SEASON: 'season',
  SIZE: 'size',
  PRODUCT_RELEVANCE: 'ProductRelevance',
  VARIANT_RELEVANCE: 'VariantRelevance',
};

const facetTypes = {
  TERM: 'Term',
};

const currencyTypes = {
  EUR: '€',
};

const fileTypes = {
  CSV: 'text/csv',
};

const userRoles = {
  SALES_REPRESENTATIVE: 'SALES_REPRESENTATIVE',
  B2B_CONSUMER: 'B2B_CONSUMER',
  SUPPORT_AGENT: 'SUPPORT_AGENT',
};

const urlParams = {
  CAT: 'cat',
  SORT: 'sort',
  PAGE: 'page',
  CONTENT_LIMIT: 'limit',
  PRODUCT: 'ids',
  KEY: 'key',
  NOTES: 'notes',
  ID: 'id',
  TAB: 'tab',
  STOCK: 'hide-unavailable',
};

const wishlistTabs = {
  WISHLIST: 'WISHLIST',
  ALL_PRODUCTS: 'ALL_PRODUCTS',
};

const constants = {
  RAW_TRANSLATIONS: '_rawTranslations',
  TYPE: '_type',
  ID: 'id',
  KEY: '_key',
  REQUESTED_DATE_FORMAT: 'DD-MM-YYYY',
  SHIPMENT_DATE_DISPLAY_FORMAT: 'DD MMM YYYY',
  REF: '_ref',
};

const unRestrictedPaths = [
  '/login',
  '/sites',
  '/forgot-password',
  '/create-password',
  '/password-reset',
  '/impersonate',
  '/switch-account',
];

const semiRestrictedPaths = [
  '/terms-and-conditions',
  '/privacy-policy',
  '/help',
  '/404',
];
const catalogueTypes = {
  PRE_ORDER: 'PreOrder',
  RE_ORDER: 'ReOrder',
};

const excludedStatuses = [
  'New',
  'Processing',
  'Failed',
];

// TODO: Add value while adding new brands
const defaultColorCodes = [
  'F',
  'H000',
  '00',
];

const cartWarningModalOptions = {
  REMOVE_PRODUCTS: 'RemoveProducts',
};
const errorCodes = {
  EMPTY_CART: 'EmptyCart',
  OUT_OF_STOCK: 'OutOfStock',
  CATALOG_TYPE_CONFLICT: 'CatalogTypeConflict',
  SHIPMENT_LIMIT_EXCEEDED: 'SHIPMENT_LIMIT_EXCEEDED',
  INVALID_SHIPMENT_DATE: 'InvalidRequestedShipmentDate',
  INVALID_CUSTOMER_CLASSIFICATION: 'InvalidCustomerClassification',
  SALES_COMPANIES_EMPTY: 'SALES_COMPANIES_EMPTY',
  AUTH_FAILED: 'AUTH_FAILED',
};

const badgeTypes = {
  KEY_PRODUCT: {
    key: 'KEY_PRODUCT',
    value: 'Key product',
    bgColor: 'success',
    textColor: '',
    badgeText: 'KEY PRODUCT',
  },
  MARKETING_PRODUCT: {
    key: 'MARKETING_PRODUCT',
    value: 'MarketingProduct',
    bgColor: 'warning',
    textColor: 'text-white',
    badgeText: 'MARKETING',
  },
  NEW_PRODUCT: {
    key: 'NEW_PRODUCT',
    value: 'NewProduct',
    bgColor: 'danger',
    textColor: '',
    badgeText: 'NEW',
  },
  NEW_COLOR: {
    key: 'NEW_COLOR',
    value: 'New color',
    bgColor: 'green',
    textColor: '',
    badgeText: 'NEW COLOR',
  },
};
const confirmedBookingOrderStatus = [
  'Invoiced',
  'Backorder',
];
const downloadStatus = [
  'STARTED',
  'COMPLETED',
];
const purchaseErrors = {
  PRODUCT_NOT_AVAILABLE: 'productNotAvailable',
  PRODUCT_BRAND_CONFLICT: 'productBrandConflict',
  PRODUCT_ORDER_TYPE_CONFLICT: 'productOrderTypeConflict',
  PRODUCT_BRAND_ORDER_TYPE_CONFLICT: 'productBrandOrderTypeConflict',
};
const brandNames = {
  FJ: 'Fjallraven',
  HW: 'Hanwag',
  PR: 'Primus',
  RR: 'Royal Robbins',
};
module.exports = {
  facetNames,
  facetTypes,
  currencyTypes,
  fileTypes,
  userRoles,
  urlParams,
  wishlistTabs,
  constants,
  unRestrictedPaths,
  semiRestrictedPaths,
  defaultColorCodes,
  excludedStatuses,
  catalogueTypes,
  facetCodes,
  cartWarningModalOptions,
  errorCodes,
  badgeTypes,
  confirmedBookingOrderStatus,
  downloadStatus,
  purchaseErrors,
  brandNames,
};
