const config = {
  baseURL: process.env.GATSBY_API_HOST || 'https://b2b-api-fenix-dev.fenixoutdoor.com',
  authServerHost: process.env.GATSBY_CAS_HOST || 'https://b2b-cas-fenix-dev.fenixoutdoor.com',
  environment: process.env.GATSBY_ENVIRONMENT || 'local',
  occtooServiceURL: process.env.GATSBY_OCCTOO_API_URL || 'https://global.occtoo.com',
  occtooApiKey: process.env.GATSBY_OCCTOO_API_KEY || 'ZWY2YTIzMzI0M2Y5NDE3Yzk0MGQzYmQwMjgyY2IwZGU=',
  occtooApiVersion: process.env.GATSBY_OCCTOO_API_VERSION || 'v32',
  fenixIntegrationServiceURL: process.env.GATSBY_FENIX_INTEGRATION_API_URL || 'https://apidev.fenixoutdoor.com/external/mozcom',
  fenixIntegrationServiceApiKey: process.env.GATSBY_FENIX_INTEGRATION_API_KEY || '49762f4b0ef74f218a71cc023e1505fe',
  GTM_ENABLED: process.env.GATSBY_GTM_ENABLED ? JSON.parse(process.env.GATSBY_GTM_ENABLED) : false,
  GTM_DEV_ENABLED: process.env.GATSBY_GTM_DEV_ENABLED ? JSON.parse(process.env.GATSBY_GTM_DEV_ENABLED) : false,
  GTM_ID: process.env.GATSBY_GTM_ID || 'GTM-WN39F9C',
  LOG_ROCKET_ENABLED: process.env.GATSBY_LOG_ROCKET_ENABLED ? JSON.parse(process.env.GATSBY_LOG_ROCKET_ENABLED) : false,
  LOG_ROCKET_API_KEY: process.env.GATSBY_LOG_ROCKET_API_KEY || 'qbplab/test',
  SENTRY_ENABLED: process.env.GATSBY_SENTRY_ENABLED ? JSON.parse(process.env.GATSBY_SENTRY_ENABLED) : true,
  SENTRY_DSN: process.env.GATSBY_SENTRY_DSN || 'https://e12e4a1e5eed48d6b6db82a0910a175c@b2b-sentry-fenix.mozcomtest.com/2',
  SENTRY_SAMPLE_RATE: process.env.GATSBY_SENTRY_SAMPLE_RATE || 1,
  sanity: {
    projectName: process.env.GATSBY_SANITY_PROJECT_NAME || 'fenixoutdoor-digital-common',
    projectId: process.env.GATSBY_SANITY_PROJECT_ID || 'v3wenpx7',
    dataset: process.env.GATSBY_SANITY_PROJECT_DATASET || 'b2b-dev',
    token: process.env.GATSBY_SANITY_READ_TOKEN || 'skEVhXcUwYniw7I9chOxMc9gzWGjrc5Zr3kRtGFVjwEMjjMxHB19oVUmzfGwCJOBu6FMUyJb6nxpnwFqUGWvAigGlqz8iAIwz9Hb8wTtkYSbez2ydT9Ffsjvl5agg5GP6fHd6EH4PenTbpOlfPHml0aJXAIS4qcnnKuTbVxIwHgFKy0hLvWz',
    watchMode: process.env.GATSBY_SANITY_WATCH_MODE ? JSON.parse(process.env.GATSBY_SANITY_WATCH_MODE) : false,
    overlayDrafts: process.env.GATSBY_SANITY_OVERLAY_DRAFTS ? JSON.parse(process.env.GATSBY_SANITY_OVERLAY_DRAFTS) : false,
    useCdn: false,
    withCredentials: true,
  },
  LOCAL_STORAGE_ENCRYPTION_KEY: process.env.GATSBY_MOZCOM_LOCAL_STORAGE_ENCRYPTION_KEY || 'I3Apr12O2I',
  MOZCOM_RETAILER_ID: process.env.GATSBY_MOZCOM_RETAILER_ID || '5ed2219368ddf31d5755818b',
  showConfig: process.env.GATSBY_SHOW_CONFIG ? JSON.parse(process.env.GATSBY_SHOW_CONFIG) : true,
  buildFlags: {
    FAST_DEV: process.env.GATSBY_FAST_DEV ? JSON.parse(process.env.GATSBY_FAST_DEV) : true,
    PARALLEL_SOURCING: process.env.GATSBY_PARALLEL_SOURCING ? JSON.parse(process.env.GATSBY_PARALLEL_SOURCING) : false,
    PRESERVE_FILE_DOWNLOAD_CACHE: process.env.GATSBY_PRESERVE_FILE_DOWNLOAD_CACHE ? JSON.parse(process.env.GATSBY_PRESERVE_FILE_DOWNLOAD_CACHE) : true,
  },
  customerClassificationMapping: { K: ['K'], A: ['A'], B: ['B'], D: ['D'] },
  productBatchCount: process.env.GATSBY_PRODUCT_BATCH_COUNT ? process.env.GATSBY_PRODUCT_BATCH_COUNT : 100,
  waitForPDPCompletion: process.env.GATSBY_WAIT_FOR_PDP_COMPLETION ? JSON.parse(process.env.GATSBY_WAIT_FOR_PDP_COMPLETION) : false,
  orderUploadCsvTemplateUrl: process.env.GATSBY_ORDER_UPLOAD_CSV_TEMPLATE || 'https://stcdndev001.blob.core.windows.net/content/sample-csv/order-template.csv',
  storeCartDetailsInLocalStorage: process.env.GATSBY_STORE_CART_DETAILS || false,
  storeOrderDetailsInLocalStorage: process.env.GATSBY_STORE_ORDER_DETAILS || false,
  storeOrderProposalsInLocalStorage: process.env.GATSBY_STORE_ORDER_PROPOSALS || false,
  storeWishlistDetailsInLocalStorage: process.env.GATSBY_STORE_WISHLIST_DETAILS || false,
  addProductsToCartLimit: process.env.GATSBY_ADD_PRODUCTS_TO_CART_COUNT ? process.env.GATSBY_ADD_PRODUCTS_TO_CART_COUNT : 1,
  addCsvToCartLimit: process.env.GATSBY_ADD_CSV_PRODUCTS_TO_CART_COUNT ? process.env.GATSBY_ADD_CSV_PRODUCTS_TO_CART_COUNT : 50,
  initialReOrderContentCount: process.env.GATSBY_INITIAL_REORDER_CONTENT_COUNT || 1,
  initialPreOrderContentCount: process.env.GATSBY_INITIAL_PREORDER_CONTENT_COUNT || 1,
  initialPDPContentCount: process.env.GATSBY_INITIAL_PDP_CONTENT_COUNT || 1,
  initialPLPContentCount: process.env.GATSBY_INITIAL_PLP_CONTENT_COUNT || 1,
  sanityImageQuality: process.env.GATSBY_SANITY_IMAGE_QUALITY || 1,
  siteOnMaintanance: process.env.GATSBY_ON_MAINTANANCE || false,
};
module.exports = config;
