/**
 * Copyright(c) 2021 Mozanta Technologies Private Ltd.
 *
 * All rights reserved.
 *
 * This software is the confidential and proprietary information of Mozanta ("Confidential
 * Information"). You shall not disclose such Confidential Information and shall use it only in
 * accordance with the terms of the contract agreement you entered into with Mozanta.
 *
 * @author Anokh J Ajai
 */

const CryptoJS = require('crypto-js');
const config = require('../config/config');

const isBrowser = () => typeof window !== 'undefined' && window;

const encryptData = data => {
  const key = config.LOCAL_STORAGE_ENCRYPTION_KEY;
  return CryptoJS.AES.encrypt(data, key).toString();
};

const decryptData = secret => {
  const key = config.LOCAL_STORAGE_ENCRYPTION_KEY;
  let decrypted;
  try {
    decrypted = CryptoJS.AES.decrypt(secret, key).toString(CryptoJS.enc.Utf8);
  } catch (error) {
    console.log('Error while decrypting', error);
  }
  return decrypted;
};

const UserDataProps = {
  TOKEN: 'C010D219EB864259A79E7D3C1F7610F9',
  REFRESH_TOKEN: 'D082B020548A4A4496027F6AE764534A',
  FENIX_USER: 'E6561D7124EB41549F9D00CC33300532',
  SELECTED_BRAND_ID: '6804138F6C6C46D3A005228813F58472',
  SELECTED_LOCALE: 'DA85D1879D1D47F28E08BDDB06135E0E',
  SELECTED_SITE_ID: '1F31A7FE2C9C42338A59671E38CD2251',
  SELECTED_SITE_BASE_URL: 'DDC3DE62F8E14FDF9043542FD2CD210D',
  SELECTED_SITE_NAME: 'B00EBF46C19642D8A0CFEEBB4F1FBD8D',
  SELECTED_LOCATION_ID: '9B6F3A5B950646ABB3709053E140FFD2',
  CURRENCY_CODE: 'A09E9400A14311EB9D0D0800200C9A66',
  CURRENCY_FORMAT: 'C78F4E67A9C542EA9749302B1CCA713B',
  DISABLE_CHECKOUT: '54BA00ED3A3D4D489328FCF531BBD7F0',
  CUSTOMER_CLASSIFICATION: '77260D92B25C11EB99E70800200C9A66',
  IMPERSONATED_USER: '68530620C39F11EBBA2F0800200C9A66',
  CATALOGUE_TYPE: '355668024E36419BADBCDF8FAE4EBA17',
  SELECTED_COMPANY: '68F1B43E16C944FB9B1F18395A84C6F0',
  USER_ROLE: '8B1F3C5020F111EC932F0800200C9A66',
  IMPERSONATE_USER: 'EEAC090891D0467AA48D792EA6EC8ADC',
  SELECTED_MARKET: 'TCYJZAME2JCX5ARAK2EWJ7LN6FCHEADQ',
  LAST_EDITED_SHIPMENT: 'E5D9D297BE594C8EB2601838CE70F2F4',
  PRODUCTS_IN_CART: 'SGDHHIWLDE2SXQOIH5BMQCVE1JN1TNRQ', // For storing cart details in Local Storage
  ORDERS: '35759B3BD3E74370A72BB985F7186D0A', // For storing order details in Local Storage
  WISH_LISTS: 'D71F63D1DF6144A9920A0EAF3C7C05F1', // For storing wish-list details in Local Storage
  ORDER_PROPOSALS: 'A1C6DA90E97611EDAFC50800200C9A66', // For storing order-proposals in Local Storage
  PERFORMANCE: '44ADBA6066C511ED8A300800200C9A66', // For performance
};

/**
 * Sets a global property. Using html5 local storage
 * @param {String} propertyName
 * @param {Object} propertyValue
 */
const setProperty = (propertyName, propertyValue) => {
  if (isBrowser() && propertyValue) {
    window.localStorage.setItem(propertyName, encryptData(propertyValue));
  }
};

/**
 * Retrieve global property by name
 * @param {String} propertyName
 */
const getProperty = propertyName => (isBrowser() && window.localStorage.getItem(propertyName) ? decryptData(window.localStorage.getItem(propertyName)) : null);

/**
 * Remove global property by name
 * @param {String} propertyName
 */
const clearProperty = propertyName => {
  if (isBrowser()) {
    window.localStorage.removeItem(propertyName);
  }
};

/**
 * Holds logged in user details
 */
const UserData = {
  setToken: value => setProperty(UserDataProps.TOKEN, value),
  getToken: () => getProperty(UserDataProps.TOKEN),
  clearToken: () => clearProperty(UserDataProps.TOKEN),

  setRefreshToken: value => setProperty(UserDataProps.REFRESH_TOKEN, value),
  getRefreshToken: () => getProperty(UserDataProps.REFRESH_TOKEN),
  clearRefreshToken: () => clearProperty(UserDataProps.REFRESH_TOKEN),

  setUser: value => {
    if (value) {
      setProperty(UserDataProps.FENIX_USER, JSON.stringify(value));
    } else {
      clearProperty(UserDataProps.FENIX_USER);
    }
  },
  getUser: () => (isBrowser() && getProperty(UserDataProps.FENIX_USER) && getProperty(UserDataProps.FENIX_USER) !== 'null'
    ? JSON.parse(getProperty(UserDataProps.FENIX_USER))
    : null),
  clearUser: () => clearProperty(UserDataProps.FENIX_USER),

  setBrandId: value => setProperty(UserDataProps.SELECTED_BRAND_ID, value),
  getBrandId: () => getProperty(UserDataProps.SELECTED_BRAND_ID),
  clearBrandId: () => clearProperty(UserDataProps.SELECTED_BRAND_ID),

  setLocationId: value => setProperty(UserDataProps.SELECTED_LOCATION_ID, value),
  getLocationId: () => getProperty(UserDataProps.SELECTED_LOCATION_ID),
  clearLocationId: () => clearProperty(UserDataProps.SELECTED_LOCATION_ID),

  setLocale: value => setProperty(UserDataProps.SELECTED_LOCALE, value),
  getLocale: () => getProperty(UserDataProps.SELECTED_LOCALE),
  clearLocale: () => clearProperty(UserDataProps.SELECTED_LOCALE),

  setCurrencyCode: value => setProperty(UserDataProps.CURRENCY_CODE, value),
  getCurrencyCode: currency => getProperty(UserDataProps.CURRENCY_CODE) || currency,
  clearCurrencyCode: () => clearProperty(UserDataProps.CURRENCY_CODE),

  setSiteId: value => setProperty(UserDataProps.SELECTED_SITE_ID, value),
  getSiteId: () => getProperty(UserDataProps.SELECTED_SITE_ID),
  clearSiteId: () => clearProperty(UserDataProps.SELECTED_SITE_ID),

  setBaseUrl: value => setProperty(UserDataProps.SELECTED_SITE_BASE_URL, value),
  getBaseUrl: () => getProperty(UserDataProps.SELECTED_SITE_BASE_URL),
  clearBaseUrl: () => clearProperty(UserDataProps.SELECTED_SITE_BASE_URL),

  setSiteName: value => setProperty(UserDataProps.SELECTED_SITE_NAME, value),
  getSiteName: () => getProperty(UserDataProps.SELECTED_SITE_NAME),
  clearSiteName: () => clearProperty(UserDataProps.SELECTED_SITE_NAME),

  setCurrencyDisplayFormat: value => setProperty(UserDataProps.CURRENCY_FORMAT, value),
  getCurrencyDisplayFormat: value => getProperty(UserDataProps.CURRENCY_FORMAT) || value,
  clearCurrencyDisplayFormat: () => clearProperty(UserDataProps.CURRENCY_FORMAT),

  setDisableCheckout: value => setProperty(UserDataProps.DISABLE_CHECKOUT, value),
  getDisableCheckout: () => (getProperty(UserDataProps.DISABLE_CHECKOUT) === 'true' ? JSON.parse(getProperty(UserDataProps.DISABLE_CHECKOUT)) : null),
  clearDisableCheckout: () => clearProperty(UserDataProps.DISABLE_CHECKOUT),

  setCustomerClassification: value => setProperty(UserDataProps.CUSTOMER_CLASSIFICATION, value),
  getCustomerClassification: () => getProperty(UserDataProps.CUSTOMER_CLASSIFICATION),
  clearCustomerClassification: () => clearProperty(UserDataProps.CUSTOMER_CLASSIFICATION),

  setImpersonatedUser: value => setProperty(UserDataProps.IMPERSONATED_USER, value),
  getImpersonatedUser: () => getProperty(UserDataProps.IMPERSONATED_USER),
  clearImpersonatedUser: () => clearProperty(UserDataProps.IMPERSONATED_USER),

  setCatalogueType: value => setProperty(UserDataProps.CATALOGUE_TYPE, value),
  getCatalogueType: () => getProperty(UserDataProps.CATALOGUE_TYPE),
  clearCatalogueType: () => clearProperty(UserDataProps.CATALOGUE_TYPE),

  setSalesCompanyId: value => setProperty(UserDataProps.SELECTED_COMPANY, value),
  getSalesCompanyId: () => getProperty(UserDataProps.SELECTED_COMPANY),
  clearSalesCompanyId: () => clearProperty(UserDataProps.SELECTED_COMPANY),

  setUserRole: value => setProperty(UserDataProps.USER_ROLE, value),
  getUserRole: () => getProperty(UserDataProps.USER_ROLE),
  clearUserRole: () => clearProperty(UserDataProps.USER_ROLE),

  setImpersonateUser: value => setProperty(UserDataProps.IMPERSONATE_USER, value),
  getImpersonateUser: () => (getProperty(UserDataProps.IMPERSONATE_USER) === 'true' ? JSON.parse(getProperty(UserDataProps.IMPERSONATE_USER)) : null),
  clearImpersonateUser: () => clearProperty(UserDataProps.IMPERSONATE_USER),

  setMarket: value => setProperty(UserDataProps.SELECTED_MARKET, value),
  getMarket: () => getProperty(UserDataProps.SELECTED_MARKET),
  clearMarket: () => clearProperty(UserDataProps.SELECTED_MARKET),

  setLastEditedShipment: value => setProperty(UserDataProps.LAST_EDITED_SHIPMENT, value),
  getLastEditedShipment: () => getProperty(UserDataProps.LAST_EDITED_SHIPMENT),
  clearLastEditedShipment: () => clearProperty(UserDataProps.LAST_EDITED_SHIPMENT),

  setOrders: value => setProperty(UserDataProps.ORDERS, JSON.stringify(value)),
  getOrders: () => getProperty(UserDataProps.ORDERS),
  clearOrders: () => clearProperty(UserDataProps.ORDERS),

  setProductsInCart: value => setProperty(UserDataProps.PRODUCTS_IN_CART, JSON.stringify(value)),
  getProductsInCart: () => (getProperty(UserDataProps.PRODUCTS_IN_CART) && getProperty(UserDataProps.PRODUCTS_IN_CART) !== 'null'
    ? JSON.parse(getProperty(UserDataProps.PRODUCTS_IN_CART)) : null),
  clearProductsInCart: () => clearProperty(UserDataProps.PRODUCTS_IN_CART),

  setWishLists: value => setProperty(UserDataProps.WISH_LISTS, JSON.stringify(value)),
  getWishLists: () => getProperty(UserDataProps.WISH_LISTS),
  clearWishLists: () => clearProperty(UserDataProps.WISH_LISTS),

  setOrderProposals: value => setProperty(UserDataProps.ORDER_PROPOSALS, JSON.stringify(value)),
  getOrderProposals: () => getProperty(UserDataProps.ORDER_PROPOSALS),
  clearOrderProposals: () => clearProperty(UserDataProps.ORDER_PROPOSALS),

  setPerformance: value => setProperty(UserDataProps.PERFORMANCE, value),
  getPerformance: () => getProperty(UserDataProps.PERFORMANCE),
  clearPerformance: () => clearProperty(UserDataProps.PERFORMANCE),

  setAll: userData => {
    const {
      token, refreshToken, user, brandId, locationId, locale, siteBaseUrl, currencyCode, currencyDisplay,
      siteName, siteId, impersonateLoggedIn, customerClassification, catalogueType, salesCompanyId, role, market, lastEditedShipment, productsInCart, orders, wishLists, performance, orderProposals,
    } = userData;
    if (token) { setProperty(UserDataProps.TOKEN, token); }
    if (refreshToken) { setProperty(UserDataProps.REFRESH_TOKEN, refreshToken); }
    if (user) { setProperty(UserDataProps.FENIX_USER, user); } else { clearProperty(UserDataProps.FENIX_USER); }
    if (brandId) { setProperty(UserDataProps.SELECTED_BRAND_ID, brandId); }
    if (locationId) { setProperty(UserDataProps.SELECTED_LOCATION_ID, locationId); }
    if (locale) { setProperty(UserDataProps.SELECTED_LOCALE, locale); }
    if (currencyCode) { setProperty(UserDataProps.CURRENCY_CODE, currencyCode); }
    if (siteBaseUrl) { setProperty(UserDataProps.SELECTED_SITE_BASE_URL, siteBaseUrl); }
    if (currencyDisplay) { setProperty(UserDataProps.CURRENCY_FORMAT, currencyDisplay); }
    if (siteName) { setProperty(UserDataProps.SELECTED_SITE_NAME, siteName); }
    if (siteId) { setProperty(UserDataProps.SELECTED_SITE_ID, siteId); }
    if (impersonateLoggedIn) { setProperty(UserDataProps.DISABLE_CHECKOUT, impersonateLoggedIn); }
    if (customerClassification) { setProperty(UserDataProps.CUSTOMER_CLASSIFICATION, customerClassification); }
    if (catalogueType) { setProperty(UserDataProps.CATALOGUE_TYPE, catalogueType); }
    if (salesCompanyId) { setProperty(UserDataProps.SELECTED_COMPANY, salesCompanyId); }
    if (role) { setProperty(UserDataProps.USER_ROLE, role); }
    if (impersonateLoggedIn) { setProperty(UserDataProps.IMPERSONATE_USER, impersonateLoggedIn); }
    if (market) { setProperty(UserDataProps.SELECTED_MARKET, market); }
    if (lastEditedShipment) { setProperty(UserDataProps.LAST_EDITED_SHIPMENT, lastEditedShipment); }
    if (orders) { setProperty(UserDataProps.ORDERS, orders); }
    if (productsInCart) { setProperty(UserDataProps.PRODUCTS_IN_CART, productsInCart); }
    if (wishLists) { setProperty(UserDataProps.WISH_LISTS, wishLists); }
    if (orderProposals) { setProperty(UserDataProps.ORDER_PROPOSALS, orderProposals); }
    if (performance) { setProperty(UserDataProps.PERFORMANCE, performance); }
  },

  clearAll: () => {
    clearProperty(UserDataProps.TOKEN);
    clearProperty(UserDataProps.REFRESH_TOKEN);
    clearProperty(UserDataProps.FENIX_USER);
    clearProperty(UserDataProps.SELECTED_BRAND_ID);
    clearProperty(UserDataProps.SELECTED_LOCATION_ID);
    clearProperty(UserDataProps.SELECTED_LOCALE);
    clearProperty(UserDataProps.CURRENCY_CODE);
    clearProperty(UserDataProps.SELECTED_SITE_BASE_URL);
    clearProperty(UserDataProps.CURRENCY_FORMAT);
    clearProperty(UserDataProps.SELECTED_SITE_NAME);
    clearProperty(UserDataProps.SELECTED_SITE_ID);
    clearProperty(UserDataProps.DISABLE_CHECKOUT);
    clearProperty(UserDataProps.CUSTOMER_CLASSIFICATION);
    clearProperty(UserDataProps.IMPERSONATED_USER);
    clearProperty(UserDataProps.CATALOGUE_TYPE);
    clearProperty(UserDataProps.SELECTED_COMPANY);
    clearProperty(UserDataProps.USER_ROLE);
    clearProperty(UserDataProps.SELECTED_MARKET);
    clearProperty(UserDataProps.LAST_EDITED_SHIPMENT);
    clearProperty(UserDataProps.ORDERS);
    clearProperty(UserDataProps.PRODUCTS_IN_CART);
    clearProperty(UserDataProps.WISH_LISTS);
    clearProperty(UserDataProps.ORDER_PROPOSALS);
    clearProperty(UserDataProps.PERFORMANCE);
    if (isBrowser()) {
      window.localStorage.clear();
    }
  },
};

Object.freeze(UserData);
module.exports = { UserData, isBrowser, encryptData, decryptData };
